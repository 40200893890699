import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import Form from 'react-bootstrap/Form';

import ButtonBTN from 'react-bootstrap/Button';
const ForgetPasswordModal = ({ _this }) => {
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    let interval = null;

    if (_this.isButtonDisabled) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    if (countdown <= 0) {
      clearInterval(interval);
      _this.setButtonDisabled(false);
      setCountdown(60);
    }

    return () => {
      clearInterval(interval);
    };
  }, [_this.isButtonDisabled, countdown]);
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.forgetPasswordModalVisibility}
      onHide={() => _this.setForgetPasswordModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Forgot Password</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setForgetPasswordModalVisibility(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 flex mr-2">
          <div className="flex flex-row items-center gap-1 mb-4">
            <Form.Control
              label="Email"
              placeholder="@email"
              type="text"
              className="w-full"
              required
              value={_this.forgetPasswordEmail}
              onChange={(e) => _this.setForgetPasswordEmail(e.target.value)}
            />
            <ButtonBTN
              className="items-baseline p-2 text-white bg-blue-600"
              disabled={_this.isButtonDisabled}
              onClick={() => {
                _this.handelOTPSend();
              }}
            >
              {_this.isButtonDisabled ? `${countdown}s` : 'OTP'}
            </ButtonBTN>
          </div>
          <Input
            label="OTP"
            placeholder="@otp"
            type="number"
            required
            value={_this.otp}
            onChange={(e) => _this.setOTP(e.target.value)}
          />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.handleForgetPasswordSubmit();
          }}
        >
          Verify
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ForgetPasswordModal;
