import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Card, Button, Input } from 'components';
import { AiFillCloseCircle } from 'react-icons/ai';
import Form from 'react-bootstrap/Form';

import ButtonBTN from 'react-bootstrap/Button';
const ChangePasswordModal = ({ _this }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      show={_this.changePasswordModalVisibility}
      onHide={() => _this.setChangePasswordModalVisibility(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
        <AiFillCloseCircle
          size={30}
          onClick={() => _this.setChangePasswordModalVisibility(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex flex-row">
        <Card className="mb-3 mt-2">
          <div className="text-md text-slate-500 mt-1">
            <Form className="bg-slate-100 mt-2 p-3 rounded">
              <Input
                label="Password"
                placeholder="@Password"
                type="password"
                required
                value={_this.newPassword}
                onChange={(e) => _this.setNewPassword(e.target.value)}
              />
              <Input
                label="Confirm Password"
                placeholder="@Confirm Password"
                type="password"
                required
                value={_this.confirmNewPassword}
                onChange={(e) => _this.setConfirmNewPassword(e.target.value)}
              />
            </Form>
          </div>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            _this.handelChangePasswordSubmit();
          }}
        >
          Change Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
